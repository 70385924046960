.App {
  text-align: center;
  overflow:hidden  !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(12, 12, 12);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .map-container{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-container iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
} */
/* .credential-scroll div {
  overflow-x: hidden !important;
} */
.credential-scroll div {
  overflow-x: hidden !important;
}


.navbar-nav { white-space: nowrap; }
.nav-item { display: inline; }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) { 
.Navbar{
  display: block;
}

 }

 @media only screen and (max-width: 1199px) {
  #logoto {
      width: 200px !important;
      height: 50px !important;
  }
}

/* @media only screen and (min-width: 1200px) {
  #logoto {
    width: 70px !important;
      height: 37px !important;
  }
} */